<template>
  <v-row justify="start">
    <v-dialog v-model="dialogExist" persistent scrollable max-width="700px">
      <v-card>
        <v-toolbar color="indigo">
          <span class="headline white--text">list Peserta lama</span>
        </v-toolbar>
        <v-card-text>
          <v-container class="d-flex flex-wrap justify-center" v-if="existUser">
            <div v-for="user in existUser.data" :key="user.id">
              <v-alert
                border="left"
                colored-border
                color="indigo"
                elevation="2"
                class="px-3 py-2 ma-1 user"
                width="300"
                @click="pick(user)"
              >
                <div>
                  <section>
                    <small class="grey--text">Email</small>
                    <h3>{{ user.emailUser }}</h3>
                  </section>
                  <section>
                    <small class="grey--text">Nama Lengkap</small>
                    <h3>{{ user.namaLengkapUser }}</h3>
                  </section>
                </div>
              </v-alert>
            </div>
            <!-- {{ existUser }} -->
            <v-dialog v-model="loading" persistent width="300">
              <v-card class="indigo" dark>
                <v-card-text>
                  silahkan tunggu...
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('false')">
            Keluar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "existUser",

  props: ["dialogExist", "idGrup"],
  computed: {
    ...mapState({
      existUser: (state) => state.existUser.listUser,
    }),
  },
  data() {
    return {
        loading: false
    };
  },
  mounted() {
    this.$store.dispatch("existUser/listExistUser");
  },
  methods: {
    pick(item) {
      let data = {
        id: item.idUser,
        id_group: this.idGrup,
      };
      this.loading = true;
      this.$store
        .dispatch("existUser/pickUser", data)
        .then((data) => {
          this.$emit("false");
          this.loading = false
          console.log(data);
          Swal.fire({
            icon: "success",
            title: data.pesan,
            showConfirmButton: false,
            timer: 2000,
            position: "top",
            toast: true,
          });
        })
        .catch((err) => {
          console.log(err);
          //   Swal.fire({
          //     icon: "success",
          //     title: data.message,
          //     showConfirmButton: false,
          //     timer: 2000,
          //     position: "top",
          //     toast: true,
          //   });
        });
    },
  },
};
</script>

<style scoped>
.user {
  cursor: pointer;
}
.user:hover {
  background: rgb(190, 213, 220);
}
</style>
