<template>
  <div class="bg-default pa-3">
    <nav class="pa-3">
      <router-link
        :to="`/event-detail/${grupById.pelatihan_id}`"
        style="text-decoration: none"
      >
        <v-btn dark fab small color="indigo">
          <v-icon dark> mdi-arrow-left </v-icon>
        </v-btn>
      </router-link>
    </nav>
    <header>
      <v-row>
        <v-col cols="12" md="4">
          <v-card>
            <v-toolbar color="indigo" dense dark><h4>Nama group</h4></v-toolbar>
            <div class="d-flex justify-space-between pa-2">
              <h2>{{ grupById.nama }}</h2>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    v-bind="attrs"
                    v-on="on"
                    @click="dialogGrup = true"
                    icon
                  >
                    <v-icon color="indigo">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit nama kelompok</span>
              </v-tooltip>
            </div>
            <div class="pa-2" v-if="dialogGrup">
              <!-- <transition name="fade"> -->
              <v-text-field
                v-model="grupById.nama"
                label="Edit Nama Kelompok"
                @input="$v.nama.$touch()"
              >
              </v-text-field>
              <div class="d-flex">
                <v-btn color="indigo" dark class="mr-4" @click="updateGrup()">
                  Ubah
                </v-btn>

                <v-btn color="error" class="mr-4" @click="dialogGrup = false">
                  Batal
                </v-btn>
              </div>
              <!-- </transition> -->
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card v-if="grupById.mentor">
            <v-toolbar color="indigo" dense dark><h4>Coach</h4></v-toolbar>
            <h2 class="pa-2">{{ grupById.mentor.nama_lengkap }}</h2>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card v-if="!importpeserta">
            <v-toolbar color="indigo" dense dark
              ><h4>Export peserta</h4></v-toolbar
            >
            <div class="button-group d-flex flex-column pa-2">
              <v-btn
                color="indigo"
                dark
                :href="`${env}/storage/import/student.xlsx`"
                target="_blank"
                @click="importpeserta = true"
              >
                <span> template peserta </span>
                <v-icon color="white"> mdi-download-circle </v-icon>
              </v-btn>
            </div>
          </v-card>
          <transition name="fade">
            <v-card v-if="importpeserta">
              <v-toolbar color="indigo" dense dark
                ><h4>Impor peserta</h4></v-toolbar
              >
              <v-file-input
                v-model="import_file"
                label="Impor Peserta"
              ></v-file-input>
              <v-card-actions>
                <v-btn
                  color="indigo"
                  dark
                  class="mr-4"
                  @click="importPeserta()"
                >
                  Import
                </v-btn>
                <v-btn
                  color="error"
                  class="mr-4"
                  @click="importpeserta = false"
                >
                  Batal
                </v-btn>
              </v-card-actions>
            </v-card>
          </transition>
        </v-col>
      </v-row>
    </header>
    <v-row>
      <v-col cols="12" md="4">
        <v-btn class="mb-2 indigo" block dark @click="dialogExist = true"
          ><v-icon>mdi-plus</v-icon>pilih peserta yang sudah ada</v-btn
        >
        <ListExistUser
          v-bind:dialogExist="dialogExist"
          v-bind:idGrup="idGrup"
          @false="closeDialog"
        />
        <v-card>
          <v-toolbar color="indigo" dense dark
            ><h4>Tambah Peserta</h4>
            <v-spacer></v-spacer>

            <v-icon dark class="mr-2">mdi-account-multiple-plus</v-icon>
          </v-toolbar>
          <form @submit.prevent="createStudent()" class="py-2 px-4">
            <v-text-field
              v-model="namaPeserta"
              :counter="100"
              :error-messages="pesertaError"
              label="Nama Lengkap"
              required
              @input="$v.namaPeserta.$touch()"
              @blur="$v.namaPeserta.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="emailPeserta"
              label="email"
              :error-messages="emailPesertaError"
              required
              counter
              @input="$v.emailPeserta.$touch()"
              @blur="$v.emailPeserta.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="emailConfirm"
              label="Ulangi email"
              :error-messages="emailConfirmError"
              required
              counter
              @input="$v.emailConfirm.$touch()"
              @blur="$v.emailConfirm.$touch()"
            ></v-text-field>
            <div class="button-group mt-5 d-flex flex-column">
              <v-btn type="createStudent" color="indigo" dark>
                <span v-if="loading" class="d-flex">
                  <Loading propsColor="white" />
                </span>
                <span v-else> Tambah </span>
              </v-btn>
            </div>
          </form>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <v-card class="temp-table">
          <v-card-title>
            Daftar Peserta
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <div>
            <v-data-table
              :loading="loadTable"
              :headers="headers"
              :items="grupById.user"
              :search="search"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <div class="d-fle">
                  <v-btn fab dark small color="cyan">
                    <v-icon dark small @click="editPage(item)">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    dark
                    small
                    color="red"
                    class="ml-3"
                    @click.prevent="deleteConfirm(item)"
                  >
                    <v-icon dark small> mdi-delete </v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
      <v-snackbar v-model="snack" color="red">
        {{ errMsg }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="closeSnack">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <EditPeserta
        v-bind:profile="profile"
        v-bind:idProfile="idProfile"
        v-bind:dialogView="dialogView"
        v-bind:compareEmail="compareEmail"
        v-on:false="dialogFalse()"
      />
    </v-row>
    <v-dialog v-model="loadExcel" hide-overlay persistent width="300">
      <v-card class="indigo" dark>
        <v-card-text>
          mengunggah...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GROUP_BY_ID } from "../../graphql/Event";
import { ADD_STUDENT, DELETE_STUDENT } from "../../graphql/Student";
import { IMPORT_PESERTA } from "../../graphql/Teacher";
import { EDIT_GROUP } from "../../graphql/Group";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required, sameAs, maxLength, email } from "vuelidate/lib/validators";
import Loading from "../_base/loader";
import EditPeserta from "./Modal/editStudent";
import ListExistUser from "./Modal/listExistUser.vue";

export default {
  name: "groupdetail",
  apollo: {
    grupById: {
      variables() {
        return { id: this.idGroup };
      },
      query: GROUP_BY_ID,
      error(error) {
        console.error(error);
      },
    },
  },

  mixins: [validationMixin],
  validations: {
    namaPeserta: { required, maxLength: maxLength(100) },
    emailPeserta: { required, email },
    emailConfirm: { required, sameAsEmail: sameAs("emailPeserta") },
  },
  components: {
    Loading,
    EditPeserta,
    ListExistUser,
  },

  computed: {
    idGroup() {
      return this.$route.params.idGroup;
    },
    loadTable() {
      return this.$apollo.queries.grupById.loading;
    },
    idProfile() {
      let id = null;
      if (!this.$apollo.queries.grupById.loading) {
        id = this.grupById.mentor.id;
      }
      return id;
    },
    pesertaError() {
      const errors = [];
      if (!this.$v.namaPeserta.$dirty) return errors;
      !this.$v.namaPeserta.required && errors.push("Nama harus di isi!");
      !this.$v.namaPeserta.maxLength &&
        errors.push("Nama hanya beoleh 100 karakter!");
      return errors;
    },
    emailPesertaError() {
      const errors = [];
      if (!this.$v.emailPeserta.$dirty) return errors;
      !this.$v.emailPeserta.email && errors.push("email tidak valid");
      !this.$v.emailPeserta.required && errors.push("email harus di isi!");
      return errors;
    },

    emailConfirmError() {
      const errors = [];
      if (!this.$v.emailConfirm.$dirty) return errors;
      !this.$v.emailConfirm.sameAsEmail && errors.push("email tidak sama!");
      !this.$v.emailConfirm.required && errors.push("email harus di isi!");
      return errors;
    },
  },
  data() {
    return {
      env: process.env.VUE_APP_GRAPHQL,
      namaPeserta: "",
      emailPeserta: "",
      emailConfirm: "",
      loadExcel: false,
      grupById: [],
      loading: false,
      search: "",
      detailStudent: false,
      value: 0,
      dialogGrup: false,
      dialogView: false,
      profile: [],
      compareEmail: "",
      import_file: null,
      importpeserta: false,
      headers: [
        { text: "id", value: "id" },
        {
          text: "Nama Lengkap",
          align: "start",
          sortable: false,
          value: "nama_lengkap",
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
        },
        { text: "Aksi", value: "actions" },
      ],
      dialogExist: false,
      idGrup: this.$route.params.idGroup,
      snack: false,
      errMsg: "",
    };
  },
  methods: {
    closeDialog() {
      this.dialogExist = false;
      this.refetchGroup()
    },
    editPage(item) {
      this.profile = item;
      this.compareEmail = item.email;
      this.dialogView = true;
    },
    dialogFalse() {
      this.dialogView = false;
      this.refetchGroup();
    },
    createStudent() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log(!this.$v.$invalid);
        return;
      } else {
        this.loading = true;
        console.log("apa yang bikin lama");
        const nama_lengkap = this.namaPeserta;
        const email = this.emailPeserta;
        const grup_id = parseInt(this.$route.params.idGroup);
        const role_id = "STUDENT";
        const created_by = parseInt(localStorage.getItem("id"));
        this.$apollo
          .mutate({
            mutation: ADD_STUDENT,
            variables: {
              nama_lengkap: nama_lengkap,
              email: email,
              grup_id: grup_id,
              role_id: role_id,
              created_by: created_by,
            },
          })
          .then(() => {
            this.loading = false;
            this.$apollo.queries.grupById.refetch();
            this.$v.namaPeserta.$reset();
            this.$v.emailPeserta.$reset();
            this.$v.emailConfirm.$reset();
            [this.namaPeserta, this.emailPeserta, this.emailConfirm] = "";
          })
          .catch((error) => {
            const err = Object.values(
              error.graphQLErrors[0].extensions.validation
            );
            console.log(error);
            this.errMsg = err[0][0];
            this.snack = true;
            this.loading = false;
          });
      }
    },
    closeSnack() {
      this.snack = false;
    },
    refetchGroup() {
      this.$apollo.queries.grupById.refetch();
    },

    updateGrup() {
      const id = parseInt(this.grupById.id);
      const id_profile = parseInt(this.grupById.mentor.id);
      console.log(this.grupById.nama);
      this.$apollo
        .mutate({
          mutation: EDIT_GROUP,
          variables: {
            id: id,
            nama: this.grupById.nama,
            id_profile: id_profile,
          },
        })
        .then(() => {
          this.refetchGroup;
          this.dialogGrup = false;
          Swal.fire({
            icon: "success",
            title: "Nama kelompok berhasil diubah",
            showConfirmButton: false,
            timer: 2000,
            position: "top",
            toast: true,
          });
        });
    },

    importPeserta() {
      const file = this.import_file;
      const grup_id = parseInt(this.grupById.id);
      this.loadExcel = true;
      this.$apollo
        .mutate({
          mutation: IMPORT_PESERTA,
          variables: {
            import: file,
            grup_id: grup_id,
          },
          context: {
            hasUpload: true,
          },
        })
        .then((data) => {
          console.log(data);
          this.refetchGroup();
          this.loadExcel = false;
          Swal.fire({
            icon: "success",
            title: "Peserta berhasil di tambahkan",
            showConfirmButton: false,
            timer: 5000,
            position: "top",
            toast: true,
          });
        })
        .catch((err) => {
          this.loadExcel = false;
          console.log(err.graphQLErrors);
          Swal.fire({
            icon: "error",
            title: err.graphQLErrors[0].message,
            confirmButtonText: "ok",
            confirmButtonColor: "#3F51B5",
            position: "top",
            toast: true,
          });
          this.loader = false;
          console.log("hai kamu");
        });
    },

    deleteConfirm(item) {
      Swal.fire({
        title: "Yakin hapus peserta?",
        // text: "Data akan dihapus dan tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$apollo
            .mutate({
              mutation: DELETE_STUDENT,
              variables: {
                id: item.id,
              },
            })
            .then(() => {
              this.refetchGroup();
              Swal.fire({
                icon: "success",
                title: "Peserta telah terhapus",
                showConfirmButton: false,
                timer: 3000,
                position: "top",
                toast: true,
              });
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.bg-default {
  min-height: 90vh;
  background: #f4f6f9;
}
</style>
